@import "../../../mixin.scss";

.wrapper {
  display: flex;
  width: 100%;
  min-width: fit-content;
  flex: 1;
  justify-content: center;
  height: 100%;

  &-start{
    justify-content: flex-start;
  }

  &-user {
    grid-area: user;
  }

  &-zones {
    grid-area: zones;
  }


  &-content-start {
    justify-content: start;
  }

  &-right-buttons-container {
    grid-area: right-buttons-container;
    justify-content: end;
    align-items: center;
    gap: 20px;
  }

  @include lower-mobile {
    grid-template-areas: "calendar content-type close-button";
  }
}
