.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: fit-content;
    height: fit-content;
    margin: 8px 0 0;
    // margin-top: 8px;

    justify-content: flex-start;

    gap: 8px;

    &-row-length {
        @mixin row-length($n) {
            li {
                width: calc(100% / $n - 4px);
            }
        } 
        &-1 {
            @include row-length(1)
        }

        &-2 {
            @include row-length(2)
        }

        &-3 {
            @include row-length(3)
        }

        &-4 {
            @include row-length(4)
        }
        &-5 {
            @include row-length(5)
        }
    }
}
