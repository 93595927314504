.video-container {
  height: 100%;
}

.map-button {
  height: 100%;

  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 20px;
  color: var(--gray-50);
  letter-spacing: -0.2px;

  &-icon {
    font-size: 28px;
  }
}
