@import "../../../mixin.scss";

.gallery {
  width: 100%;
  height: 100%;
  padding-top: 50px;

  &-slides {
    margin: 40px 0;
  }

  &-thumbnails {
    min-height: 155px;
    max-height: 155px;
    margin: 40px 0;
    padding: 0 40px;
  }

  @include lower-tablet-landscape {
    &-slides {
      margin: 0;
    }
  }

  @include lower-tablet-landscape {
    padding-top: 0;
  }
}
