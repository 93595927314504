@import "../../../../mixin.scss";

.image {
  &-container {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;
    height: 100%;
    border-radius: 16px;

    &_sharing {
      & > .image-content_loaded ~ .image-content-actions {
        visibility: visible;
        opacity: 1;
      }
    }

    &-loader {
      height: 100%;

      & > div {

        height: 100%;
      }
    }
  }

  &-content {
    height: 100%;

    object-fit: cover;

    &-actions {
      position: absolute;
      top: 0;
      left: 0;

      display: flex;
      gap: 12px;
      align-items: center;
      justify-content: center;

      width: 100%;
      height: 100%;

      visibility: hidden;
      opacity: 0;
      background-color: var(--surface-blackout);
      border-radius: 16px;

      transition: opacity 0.2s;

      &-button {
        width: 52px !important;
        height: 52px !important;

        &-icon {
          font-size: 28px;
        }
      }

      &-share {
        top: 50%;
        left: 50%;

        width: 375px;
      }
    }
  }

  &-loader {

    & > svg {
      width: 50px;
      height: 50px;

      & circle {
        stroke: var(--gray-1000) !important;
      }
    }

    &_disable {
      display: none;
    }
  }

  &_fullscreen {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    &-image {
      max-width: 100%;
      max-height: 100%;

      object-fit: cover;
    }

    &-button {
      position: absolute;
      z-index: 102;
      top: 40px;
      right: 40px;

      &-icon {
        font-size: 28px;
      }
    }
  }

  @include lower-tablet-landscape {
    &-container {
      width: 82%;
    }
  }

  @include lower-tablet {
    &-container {
      width: 100%;
      border-radius: 0;

      &-loader {
        & > div {
          width: 80%;
        }
      }
    }

    &-content {
      max-width: 100%;
      max-height: 100%;

      object-fit: contain;
    }
  }

  @media all and (min-width: 769px) {
    &-container {
      &:hover {
        cursor: pointer;

        & .image-content_loaded ~ .image-content-actions {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
