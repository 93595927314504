@import 'oelp-tokens/css/variables';

.select {
  &-wrapper {
    position: relative;
    width: 100%;
  }

  &-input {
    box-sizing: border-box;
    width: 100%;
    padding: 16px;
    color: var(--gray-800);
    font-weight: normal;
    font-size: var(--font-size-x4);
    font-family: var(--font-families-main);
    font-style: normal;
    line-height: 18px;
    letter-spacing: var(--letter-spacing-m0-28);
    border: 1.4px solid var(--gray-0);
    border-radius: 8px;
    outline: none;
    cursor: text;
    transition: all 0.12s ease-out;

    &.error {
      border: 1.4px solid var(--validation-error-main);
    }


  }

  &-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
}
