@import "../../../mixin.scss";

.filter-bottom-panel {
  display: flex;
  min-height: 96px;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-self: end;

  &-content-wrapper{
    display: flex;
    width: 100%;
    justify-content: end;
    align-items: center;
    gap: 12px;
  }

  @include lower-mobile {
    min-height: fit-content;
  }
}
