@import 'oelp-tokens/css/variables';
@import "../../../mixin.scss";

.container {
    display: inline-block;
    min-width: 107px;
    height: fit-content;

    overflow: hidden;

    width: calc(50% - 8px);

    background-color: var(--surface-front);

    box-sizing: border-box;

    flex-grow: .0;

    border-radius: 8px;

    box-shadow: 
      var(--shadow-default-mobile-x) 
      var(--shadow-default-mobile-y) 
      var(--shadow-default-mobile-blur) 
      var(--shadow-default-mobile-color);

    @include lower-mobile {
      min-width: 100%;
    }

}

.label {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-width: 107px;
  height: fit-content;

  padding: 12px 12px 12px 20px;

  background-color: var(--surface-front);

  cursor: pointer;

  border-radius: 8px;

  font-family: var(--button-desktop-lite-font-family);
  font-weight: var(--button-desktop-lite-font-weight);
  line-height: var(--button-desktop-lite-line-height);
  font-size: var(--button-desktop-lite-font-size);
  color: var(--gray-800);

  white-space: wrap;
  text-overflow: ellipsis;

  overflow: hidden;

  transition: all .12s ease-out;
  z-index: 1;

  &:hover {
    background-color: var(--interactive-light-hover);
  }

  & p {
    font-family: var(--button-desktop-lite-font-size);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-left-border {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: 8px;

  }

  &-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &-checkmark {
    position: absolute;
    display: inline-block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  &-input:checked ~ &-checkmark {
    background-color: var(--interactive-light-hover);
    z-index: -1;
  }

  @include lower-tablet-landscape {
    height: 36px;
    font-size: var(--root-font-size-tablet);

    &:hover {
      background-color: var(--surface-front);
    }
  }

  @include lower-mobile {
    font-family: var(--button-mobile-lite-font-family);
    font-weight: var(--button-mobile-lite-font-weight);
    line-height: var(--button-mobile-lite-line-height);
    font-size: var(--button-mobile-lite-font-size);
  }
}

.activeLabel {
  color: var(--gray-unchangeable-900);
}

:export {
  macro_region_pink: var(--macro-region-pink);
  macro_region_pink-alpha: var(--macro-region-pink-alpha);
  macro_region_orange: var(--macro-region-orange);
  macro_region_orange_alpha: var(--macro-region-orange-alpha);
  macro_region_dark_green: var(--macro-region-dark-green);
  macro_region_dark_green_alpha: var(--macro-region-dark-green-alpha);
  macro_region_blue: var(--macro-region-blue);
  macro_region_blue_alpha: var(--macro-region-blue-alpha);
  macro_region_violet: var(--macro-region-violet);
  macro_region_violet_alpha: var(--macro-region-violet-alpha);
  macro_region_outline_pink: var(--macro-region-outline-pink);
  macro_region_outline_orange: var(--macro-region-outline-orange);
  macro_region_outline_dark_green: var(--macro-region-outline-dark-green);
  macro_region_outline_blue: var(--macro-region-outline-blue);
  macro_region_outline_violet: var(--macro-region-outline-violet);
}
