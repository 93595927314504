@import 'oelp-tokens/css/variables';
@import "../../mixin.scss";

.wrapper {
    position: absolute;
    display: flex;
    top: calc(50% - 80px);
    left: 20px;
    width: 44px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    overflow: auto;
    z-index: 400;
    border-radius: 8px;

    &-button {
        width: 44px!important;
        height: 44px;
        padding: 0!important;
    }

    .container {
        background-color: var(--surface-front);

        .h-line {
            width: 100%;
            height: 2px;
            background-color: var(--gray-50);
        }
    }

    @include lower-tablet-landscape {
        width: 40px;
        &-button {
            width: 40px!important;
            height: 40px;
        }
    }

    @include lower-tablet {
        left: unset;
        right: 20px;
        width: 32px;
    }

    @include lower-mobile-landscape {
        &-button {
            width: 32px!important;
            height: 32px;
        }
    }
}
