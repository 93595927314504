@import 'oelp-tokens/css/variables';
@import "../../mixin.scss";

.sidebar {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  &-status-switcher {
    margin-top: 16px;
    padding: 0 20px;
  }

  .icon {
    font-size: 22px !important;
  }

  .fill-width-element {
    padding: 16px 20px;
  }

  .fill-width-bg-element {
    padding: 16px 20px;
    background-color: var(--surface-front);
  }

  .bg-element {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    padding: 16px;
    background: var(--surface-front);
    border-radius: 8px;
  }

  .buttons-container {
    display: flex;
    gap: 8px;
    margin: 0 20px;
  }
  
  &-header {
    position: relative;
    display: flex;
    flex-direction: column;

    &-top {
      display: flex;
      position: relative;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: flex-start;
  
      &-close-button {
        padding: 0!important;
      }
  
      &-title {
        display: flex;
        flex-direction: flex-start;
        align-items: center;
        justify-content: space-between;
        width: 100%;
  
        &-text {
          display: flex;
          justify-content: center;
          overflow: hidden;
          max-width: 380px;
          color: var(--gray-900);
          transition: all .5s;
        }
      }

      &-subtitle {
        display: flex;
        flex-direction: row;
  
        &-text {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          max-width: 380px;
          max-height: 2rem;
          vertical-align: middle;
          color: var(--gray-500);
        }
      }
    }
  }

  &-button-container {
    display: flex;
    gap: 8px;

    padding: 0 20px;
  }

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  @include lower-tablet {
    padding: 12px 0 24px;
  }

  @include lower-mobile {
    max-width: 100%;
    padding: 12px 0 24px;
    background-color: var(--surface-front);

    &-status-switcher {
      margin-top: 0;
      padding: 0 16px;
    }

    .bg-element {
      padding: 0;
      gap: 4px;
    }

    .buttons-container {
      margin: 0 16px;
    }
  }
}

*::-webkit-scrollbar {
  display: none;
}

.button {
  // transition: all .12s ease-out;

  // &_unselected {
  //   color: var(--gray-600)!important;
  // }
}

.editorMode{
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  max-height: 550px;
}

.sidebar-content{
  display: flex;
  flex-direction: column;
}

.image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: calc(100% - 40px);
  height: 200px;

  margin: 0 auto;

  border-radius: 8px;

  overflow: hidden;

  > img {
    height: 100%;
  }

  &-go-back-button {
    position: absolute;
    top: 8px;
    left: 32px;
  }

  @include lower-tablet {
    width: calc(100% - 40px);
    & > img {
      width: 100%;
      height: auto;
    }
  }

  @include lower-mobile {
    width: calc(100% - 32px);
    height: 128px;

    & > img {
      width: 100%;
    }
  }
}

.sidebarBody {
  padding: 16px 0;
  background: var(--surface-mid);
  width: 100%;
  overflow-x: hidden;
  //min-height: 100%;
}
