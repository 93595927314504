@import 'oelp-tokens/css/variables';

.creation-form {
    &-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 20px 30px;
        background-color: white;
        display: flex;
        flex-direction: column;
        gap: 30px;
        z-index: 10000;
        position: absolute;
        width: 100%;
        height: 100%;
        overflow-y: auto;
    
        &.invisible{
            visibility: hidden;
        }
    }
    &-header {
        font-family: var(--font-size-x3-5);
        color: var(--gray-800);
        margin: 0;
    }
    &-section {
        display: flex;
        justify-content: center;
        width: 100%;
        &.body {
            width: 100%;
            form {
                width: 70%;
            }
        }
        &.footer {
            display: flex;
            justify-content: space-between;
        }
    }
    &-submit-button {
        color: var(--gray-400);
        width: 200px;
        height: 44px;
        background-color: #f2f3f5;
        margin-right: 20%;
        margin-left: 10px;
        border: none;
        outline: none;
        transition: all .12s ease-out;
        border-radius: 8px;

        &:hover {
            cursor: default;
        }

        &.active {
            color: var(--gray-800);
            background-color: #f2f3f5;

            &:hover {
                cursor: pointer;
                background-color: #b4cdff;
            }
        }
    }
    &-cancel-button {
        color: var(--gray-800);
        border-radius: 8px;
        background-color: #f2f3f5;
        width: 200px;
        height: 44px;
        border: none;
        outline: none;
        margin-left: 20%;
        transition: all .12s ease-out;
        &.blocked{
            color: var(--gray-400);
            &:hover{
                cursor: default;
                background-color: #f2f3f5;  
            }
        }
        &:hover {
            background-color: #b4cdff;
        }
    }
    &-coords-button {
        color: var(--gray-800);
        border-radius: 8px;
        background-color: #f2f3f5;
        width: 250px;
        height: 44px;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        margin-top: 10px;
        transition: all .12s ease-out;

        &-icon{
            margin-right: 5px;
        }

        &.blocked{
            color: var(--gray-400);
            &:hover{
                background-color: #f2f3f5;
            }
        }

        &:hover {
            background-color: #b4cdff;
        }
    }
    &-input-container,
    &-textarea-container {
        display: block;
        width: 100%;
        margin-top: 20px;
        color: var(--gray-400);
        font-size: var(--font-size-x4);
        letter-spacing: -0.2px;

        input::-ms-reveal,
        input::-ms-clear {
            display: none;
        }

        ::placeholder {
            color: var(--gray-100);
        }
    }

    &-input {
        display: block;
        padding: 15px 16px;
        width: 100%;
        height: 50px;
        background-color: transparent;
        color: var(--gray-800);
        font-size: var(--font-size-x4);
        letter-spacing: var(--letter-spacing-m0-28);
        border: 1.4px solid var(--gray-50);
        box-sizing: border-box;
        border-radius: 8px;
        
        &.error{
            border-color: var(--validation-error-main);
        }
    }

    &-textarea {
        display: block;
        padding: 15px 16px;
        width: 100%;
        height: 100px;
        background-color: transparent;
        color: var(--gray-800);
        font-size: var(--font-size-x4);
        letter-spacing: var(--letter-spacing-m0-28);
        border: 1.4px solid var(--gray-50);
        box-sizing: border-box;
        border-radius: 8px;
        resize: vertical;
        
        &.error{
            border-color: var(--validation-error-main);
        }
    }

    &-invalid-field {
        margin-top: 4px;
        color: var(--validation-error-main);
        font-weight: normal;
        font-size: var(--font-size-x4);
        font-style: normal;
        line-height: 18px;
        letter-spacing: var(--letter-spacing-m0-28);
    }
    
    &-label{
        &-error{
            color: var(--validation-error-main);
        }
    }
}
.select-search {
    width: 100%;
    position: relative;
    box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
    position: relative;
    z-index: 1;
}

.select-search__value::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
}

/**
 * Input
 */
.select-search__input {
    display: block;
    height: 50px;
    width: 100%;
    padding: 15px 16px;
    background-color: transparent;
    color: var(--gray-800);
    font-size: var(--font-size-x4);
    letter-spacing: var(--letter-spacing-m0-28);
    border: 1.4px solid var(--gray-50);
    box-sizing: border-box;
    border-radius: 8px;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: var(--gray-50) !important;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
    background: #fff;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */
.select-search__options {
    list-style: none;
    padding: 0;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
    display: block;
    height: 40px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-size: var(--font-size-x3-5);
    text-align: left;
    cursor: pointer;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    background: #90b5ff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: #bad1ff;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: #bad1ff;
}

/**
 * Group
 */
.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple)
    .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: #2fcc8b;
}

.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 51px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}

.select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
}
