@import 'oelp-tokens/css/variables';
@import "../../mixin.scss";

.photos-switcher-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 8px;
  padding: 16px 0 0;
  width: 100%;

  .photos-switcher-controls-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 36px;

    .photos-witcher-edit-button, .photoswitcher-add-photo-button {
      max-width: 100%;
      width: 100%;
    }
  }

  .photos-witcher-edit-button {
    max-width: 100%;
    width: 100%;
  }

  @include lower-mobile {
    padding: 0;
  }
}
