@import 'oelp-tokens/css/variables';
@import "src/fonts/icons-font/style.scss";
@import "../../../mixin.scss";

.input-field {
    &-label {
        width: 100%;
        color: var(--gray-400);
        font-weight: normal;
        font-size: var(--font-size-x4);
        font-family: var(--font-families-main);
        font-style: normal;
        line-height: 16px;
        position: relative;
        overflow: hidden;
        box-shadow: 0px 2px 12px rgba(5, 18, 70, 0.06);

        &.label-list-view-bs {
            box-shadow: 0 0 0 1px var(--gray-100);
            border-radius: 8px;
        }

        .input-suggestion {
            position: absolute;
            display: -webkit-box;
            top: 50%;
            margin: 0;
            transform: translateY(-50%);
            width: 100%;
            padding: 1.2px 55.5px 0 55.5px;
            color: var(--gray-100);
            font-size: var(--text-desktop-x4-weight-1-font-siz);
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: text;

            & > p {
                display: inline-block;
                margin: 0;
                color: transparent;
                font-weight: var(--text-desktop-x4-weight-1-font-weigh);
                font-size: var(--text-desktop-x4-weight-1-font-siz);
                font-family: var(--text-desktop-x4-weight-1-font-family);
                font-style: var(--text-desktop-x4-weight-1-font-style);
                line-height: var(--text-desktop-x4-weight-1-line-height);
                letter-spacing: var(--text-desktop-x4-weight-1-letter-spacing);
                white-space: pre;
            }
        }
    }

    &-invalid-field {
        display: inline-block;
        margin-top: 4px;
        padding-left: 8px;
        color: var(--gray-800);
        font-weight: normal;
        font-size: var(--font-size-x4);
        font-family: var(--font-families-main);
        font-style: normal;
        line-height: 18px;
        letter-spacing: var(--letter-spacing-m0-28);
    }

    &-input {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        min-height: 48px;
        max-height: 48px;
        padding: 12px 40px 12px 54px;
        color: var(--gray-800);
        font-weight: var(--text-desktop-x4-weight-1-font-weigh);
        font-size: var(--text-desktop-x4-weight-1-font-siz);
        font-family: var(--text-desktop-x4-weight-1-font-family);
        font-style: var(--text-desktop-x4-weight-1-font-style);
        line-height: var(--text-desktop-x4-weight-1-line-height);
        letter-spacing: var(--text-desktop-x4-weight-1-letter-spacing);
        border: 1.4px solid var(--gray-0);
        border-radius: 8px;
        outline: none;
        transition: all 0.12s ease-out;
        cursor: text;

        &-icon-search {
            position: absolute;
            top: 10px;
            left: 16px;
            color: #000;
        }

        &-filter-tag {
            position: absolute;
            top: 4px;
            left: 60px;
        }

        &-close-btn {
          position: absolute;
          right: 19px;
          top: 6px;
        }

        &::placeholder {
            color: var(--gray-600);
            font-weight: var(--text-desktop-x4-weight-1-font-weigh);
            font-size: var(--text-desktop-x4-weight-1-font-siz);
            font-family: var(--text-desktop-x4-weight-1-font-family);
            font-style: var(--text-desktop-x4-weight-1-font-style);
            line-height: var(--text-desktop-x4-weight-1-line-height);
            letter-spacing: var(--text-desktop-x4-weight-1-letter-spacing);

            transition: all .12s ease-out;
        }

        &:focus::placeholder {
            color: var(--gray-200);
            
        }

        &.error {
            border: 1.4px solid var(--validation-error-main);
        }
    }

    @include lower-tablet-landscape {
        &-input {
            min-height: 40px;
            max-height: 40px;
            font-size: var(--caption-tablet-x3-25-weight-1-font-size);
            border: 1px solid var(--gray-100);

            &-close-btn {
                position: absolute;
                right: 14px;
                top: 6px;

                min-height: 26px!important;
                max-height: 26px!important;
              }
        } 
        &-label {
            .input-suggestion {
                font-size: var(--caption-tablet-x3-25-weight-1-font-size);
            }
        }

    }

    @include lower-mobile {
        &-input {
            min-height: 36px;
            max-height: 36px;
        } 
    }
}
