@import 'oelp-tokens/css/variables';

.profile-form {

    &-input-container {
        display: block;
        position: relative;
        color: var(--gray-400);
        font-size: var(--font-size-x3-25);
        letter-spacing: -0.2px;
    }

    input::-ms-reveal,
    input::-ms-clear {
        display: none;
    }

    ::placeholder{
        color: var(--gray-100);
    }

    &-button {
    width: 295px;
    height: 44px;
    border: none;
    outline: none;
    }

    &-input {
    display: block;
    padding: 15px 16px;
    width: 288px;
    height: 48px;
    background-color: transparent;
    color: var(--gray-800);
    font-size: var(--font-size-x4);
    letter-spacing: var(--letter-spacing-m0-28);
    border: 1.4px solid #E9E9E9;
    box-sizing: border-box;
    border-radius: 8px;

        &-icon-password {
            position: absolute;
            top: 20px;
            right: 0;
        }
    }

}
