@import 'oelp-tokens/css/variables';
@import "../../mixin.scss";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  width: 100%;
  background: var(--surface-front);
  border-radius: 8px;
  .search-form{
    width: 100%;
  }
  .dropdown-container{
    position: absolute;
    background: var(--surface-front);
    border-radius: 8px;
    top: 35px;
    z-index: -1;
    padding: 12px;
    padding-bottom: 0;
    display: flex;
    width: 100%;
    box-shadow: 0px 2px 12px rgba(2, 8, 32, 0.12);
  }

  @include lower-tablet-landscape {
    min-width: 100%;
  }
}
