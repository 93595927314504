@import 'oelp-tokens/css/variables';
@import "../../mixin.scss";


.bg-element {
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  padding: 16px;
  background: var(--surface-front);
  border-radius: 8px;

  @include lower-mobile {
      padding: 0;
      gap: 4px;
  }
}

.buttons-container {
  display: flex;
  gap: 8px;
  margin: 0 20px;
}

.photo-switcher {
  background-color: var(--surface-front);
}


  
