@import 'oelp-tokens/css/variables';
@import "../../mixin.scss";


.login {
    &-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        input::-ms-reveal,
        input::-ms-clear {
            display: none;
        }

        @include greater-mobile {
            padding-top: 0;
        }
    }

    &-logo {
        position: relative;
        z-index: 20;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        @include greater-mobile {
            margin-bottom: 64px;
        }

        &-text {
            color: var(--interactive-default);
            font-weight: 600;
            font-size: var(--font-size-x4);
            font-family: var(--font-families-main);
            font-style: normal;
            line-height: 22px;
            letter-spacing: -0.2px;
            margin-left: 12px;

            @include greater-mobile {
                color: var(--surface-mid);
                font-size: 29px;
                line-height: 29px;
                margin-left: 11.51px;
            }
        }
    }

    &-background {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        display: none;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include greater-mobile {
            display: block;
        }
    }
}
