@import 'oelp-tokens/css/variables';
@import "../../mixin.scss";

.object-info-box {
  padding: 16px;
  border-radius: 8px;
  
  &-content {
    max-height: 120px;
    &-text {
      margin: 0;
      padding: 0;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-overflow: hidden;

      & * {
        display: inline;
      }
    }


    &-text-opened {
      -webkit-line-clamp: unset;
    }
  }
}
