@import 'oelp-tokens/css/variables';
@import "../../mixin.scss";


.lines-item {
  border-radius: 12px;
  background-color: var(--surface-front);
  position: relative;
  display: flex;
  flex-direction: column;
  width: 47%;
  min-width: 7vw;

  border-bottom: 2px solid var(--surface-front);

  box-shadow: 
    var(--shadow-default-desktop-x) 
    var(--shadow-default-desktop-y)
    var(--shadow-default-desktop-blur)
    var(--shadow-default-desktop-color);

  &_active {
    border-bottom: 2px solid var(--interactive-default);
  }

  &__image {
    display: block;
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 12vh;
    object-fit: contain;
    background-color: var(--gray-400);
    border-radius: 8px 8px 0 0;
    background-size: cover;
    background-position: center;

    &-overlay {
      width: 100%;
      height: 100%;
      background-color: $surface-blackout;
      border-radius: 8px 8px 0 0;
      opacity: 0;
      transition: all .2s ease;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: var(--gray-50);

      transition: opacity .2s ease-out;
    }

    &-date-label {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 2px 8px 1px 16px;
        border-top-right-radius: 8px;
        background-color: var(--surface-front);

        &-span {
          white-space: nowrap;
          color: var(--gray-800);
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
  }

  &__name {
    margin: 0;
    padding: 12px 16px 12px;
    color: var(--gray-800);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: all .12s ease-out;

    cursor: pointer;

    &_active {
      color: var(--interactive-default);
    }
  }

  &__mobile-date {
    margin-left: 8px;
  }

  &__desc {
    p {
      color: var(--interactive-default);
    }
  }

  &:hover {
    .lines-item__image-overlay {
      opacity: 1;
    }
  }

  @include lower-tablet-landscape {
    padding-bottom: 6px;

    &__image {
      min-height: 98px;
    }

    &__name {
      display: flex;
      flex-direction: column;
      gap: 4px;
      font-size: var(--text-desktop-x3-5-weight-2-font-size);
      padding: 0;
      margin: 8px 8px 3px 8px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  @include lower-mobile {
    width: 100%; 
  }
}
