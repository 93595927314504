@import 'oelp-tokens/css/variables';
@import "../../../mixin.scss";

.header-list-view-background{
  position: absolute;
  display: flex;
  width: 100%;
  height: 80px;
  background-color: var(--surface-front);
}

.header-container {
  display: flex;
  align-items: flex-start;
  padding: 20px 20px 12px;
  //gap: 20px;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
  z-index: 15;
  min-height: 80px;
  transition: all .12s ease-out;

  &.header-list-view-bg {
    background-color: var(--surface-front);
  }

  &.opened {
    width: 90%
  }
  
  @include lower-tablet-landscape {
    padding: 20px;
  }
  @include lower-mobile-landscape {
    padding: 16px;
  }
}
