@import 'oelp-tokens/css/variables';

.textarea {
  position: relative;
  width: 100%;
  min-height: 200px;

  &-label {
    width: 100%;
    color: var(--gray-400);
    font-weight: normal;
    font-size: var(--font-size-x4);
    font-family: var(--font-families-main);
    font-style: normal;
    line-height: 16px;

    &-text {
        display: inline-block;
        margin-bottom: 4px;
        padding-left: 8px;
      transition: all 0.12s ease-out;

        &.error {
            color: var(--validation-error-main);
        }
    }
}

  & .editbar {
    position: absolute;

    transition: all .12s ease-out;
  }

  & .editbar-closed {
    top: -100px;
  }

  & .editbar-opened {
    top: 0;
  }
}
