@import 'oelp-tokens/css/variables';
@import "../../mixin.scss";

.content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 44px;
  padding: 20px;
  margin: 0 auto;

  overflow: scroll;

  .header {
    display: flex;
    width: 100%;
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 40px;
    flex: 1;

    &-content-wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;

        > h3 {
          margin: 0;
          padding: 5px 0;
        }

        > button {
          width: fit-content;
        }
      }

      .btn-clear {
        max-height: 23px;
      }
    }

    &-cluster-swither {
      width: 100%;
    }
  }

  @include lower-tablet-landscape {
    width: 100%;
    padding: 16px 16px 16px;
    gap: 24px;

    .body {
      gap: 24px;

      &-content-wrapper {
        .title {
          margin-top: 12px;
        }
      }
    }


  }

  @include lower-tablet {
    padding: 0 16px 16px;
  }
}
