@import 'oelp-tokens/css/variables';
@import "../../mixin.scss";

.object-info-card {
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin: 0 20px;
  background: var(--surface-front);
  border-radius: 8px;
  transition: all .12s ease-out;

  &-heading {
    display: flex;
    margin-bottom: 8px;
    color: var(--gray-400);
  }

  &-content {
    margin-top: 8px;
    display: flex;
    width: 100%;
  }
  &-footer {
    margin-top: 16px;
    display: flex;
  }

  @include lower-mobile {
    margin: 0;
    padding: 0 16px;
    &-heading {
      margin-bottom: 0;
    }
  }
}
