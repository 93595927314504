.gallery {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;

  &-swiper {
    position: relative;

    width: 100%;
    height: 100%;

    &-slide {
      display: flex;
      justify-content: center;
    }

    &-button {
      position: absolute;
      z-index: 1000;
      top: 50%;
      transform: translateY(-50%);

      display: flex;
      align-items: center;

      width: 150px;
      height: 100%;

      opacity: 1;

      transition: opacity 0.2s;

      &-next {
        right: 0;

        justify-content: flex-end;

        padding-right: 32px;
      }
      &-prev {
        left: 0;

        justify-content: flex-start;

        padding-left: 32px;
      }
      &-icon {
        font-size: 40px;
        color: var(--gray-200);
      }
      &:hover {
        cursor: pointer;

        & .gallery-swiper-button-icon {
          color: var(--interactive-hover);
        }
      }

      &_hidden {
        opacity: 0;
      }

      &_fullscreen {
        z-index: 100;

        mix-blend-mode: exclusion;

        transition: mix-blend-mode 0.2s, opacity 0.2s;

        &::before {
          content: '';

          position: absolute;
          top: 0;
          left: 0;

          width: 100%;
          height: 100%;

          opacity: 0;

          transition: opacity 0.2s;
        }

        &.gallery-swiper-button-prev {
          &::before {
            background: linear-gradient(
              90deg,
              rgb(0 0 0 / 56%) 0%,
              rgb(0 0 0 / 0%) 100%
            );
          }
        }
        &.gallery-swiper-button-next {
          &::before {
            background: linear-gradient(
              90deg,
              rgb(0 0 0 / 0%) 0%,
              rgb(0 0 0 / 56%) 100%
            );
          }
        }

        & .gallery-swiper-button-icon {
          z-index: 101;

          font-size: 48px;
          color: var(--gray-50);
        }

        &-close-button {
          position: absolute;
          z-index: 1000;
          top: 40px;
          right: 40px;

          &-icon {
            font-size: 28px;
          }
        }
      }
    }
  }

  &-thumbnails {
    width: 100%;

    & div.swiper-wrapper {
      gap: 20px;
    }

    &-item {
      width: 100%;

      &_active {
        &::after {
          content: '';

          position: absolute;
          z-index: 1000;
          top: 0;
          left: 0;

          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
