@import 'oelp-tokens/css/variables';
@import '../../mixin.scss';

.profile-page {
    &-wrapper {
        width: 100vw;
        height: 100vh;
        padding: 16px;

        overflow: scroll;
    }

    &-content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: fit-content;
        max-width: 660px;
        margin: 0 auto;
        gap: 10vh;

        &-body {
            display: flex;
            gap: 24px;
        }
    }

    &_content {
        display: flex;
        flex-direction: row;
        width: 900px;
        padding: 0 90px;
    }

    &_bio {
        display: flex;
        flex-direction: column;
        padding-top: 100px;
        align-items: flex-start;
    }

    &-header-container {
        margin-bottom: 36px;
    }

    &-menu {
        display: flex;
        width: 200px;
    }

    &-menu-button {
        display: flex;
        flex-direction: row;
        height: 40px;
        margin: 24px auto 8px auto;
        background-color: transparent;
        outline: none;
        border: none;
    }

    &-menu-icon {
        margin-left: 8px;

        &-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
        }
    }

    &_backButton {
        display: flex;
        flex-direction: row;
        gap: 8px;
        height: 32px;
        justify-content: center;
        align-items: center;
        padding: 7px 12px;
        font-size: $font-size-x4;
        line-height: 18px;
        letter-spacing: var(--letter-spacing-m0-28);
        background: $surface-front;
        box-shadow: 0 2px 12px rgba(5, 18, 70, 0.06);
        border-radius: 8px;
        border: 0;
        outline: none;

        &:hover {
            background: #b4ceff;
            box-shadow: 0 2px 12px rgba(5, 18, 70, 0.06)Hover;
        }

        &:active {
            box-shadow: 0 2px 12px rgba(5, 18, 70, 0.06)Active;
            opacity: 0.9;
        }
    }

    @include lower-tablet {

    }

    @include lower-mobile-landscape {
        &-content {
            gap: 10px;
        }


        &-content {    
            .sidebar {
                width: 100%;
            }

            .profile-page-content {
                gap: 20px;
            }
        }

        &_content {
            margin: 0;
        }
    
        &_bio {
            margin: 0;
            padding: 0;
        }
    
        &-header-container {
            margin-top: 40px;
        }

        &-menu {
            justify-content: center;
            width: 100%;
            margin-bottom: 40px;
            width: 100%;
            margin: 0;
        }
    }
}

