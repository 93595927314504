.thumbnail {
  overflow: hidden;

  width: 100%;
  height: 100%;

  opacity: 0.32;
  border-radius: 12px;

  &-image {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  &:hover {
    cursor: pointer;

    opacity: 0.8;
  }

  &_active {
    opacity: 1;
  }

  @media all and (max-width: 768px) {
    border-radius: 6px;
  }
}
