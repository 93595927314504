@import 'oelp-tokens/css/variables';

.mini-carousel-container {
  display: flex;
  flex-direction: column;
  position: relative;

  gap: 16px;
  justify-content: center;
  width: 100%;
  min-height: 120px;
  max-height: 256px;
  //border-radius: 8px;
  background-color: var(--surface-front);
  overflow: hidden;


  &-image-list {
    // position: absolute;
    // left: 0;
    // top: 0;
    display: flex;
    justify-content: start;
    flex-wrap: nowrap;
    // width: 100%;
    max-height: 120px;

    // width: calc(100% + 32px);
    // margin: 0 0 0 -16px;
    margin: 0;
    padding: 0;

    flex-direction: row;
    // padding-left: 16px;
    gap: 8px;
    overflow: hidden;
    transform: translateX(0);
    transition: transform 0.3s ease-out;
    touch-action: pan-x;
    // margin: 0;
    // padding-left: 8px;
    scrollbar-width: none;
    overflow-x: auto;

    > ul {
      padding-left: 16px;
    }
    
    ::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }
    &.sidebar-list {
      // padding: 0 16px;
    }

    &.justify-center {
      // & > ul {
        justify-content: center;
      // }
    }
    &-card-item {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      //min-width: 120px;
      // margin-left: 8px;
      border-radius: 8px;
      transition: all 0.12s ease-out;
      position: relative;
      min-width: 186px;
      // max-width: 120px;
      // width: 186px!important;
      background: #e5e5e5;

      & img {
        display: block;
        height: 120px;
        width: auto;
        width: 100%;
        max-height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 8px;
        transition: all 0.12s ease-out;
        cursor: pointer;
        &:hover {
          filter: brightness(0.6);
          -webkit-filter: brightness(0.6);
        }
        &:hover + svg.icon-edit {
          display: block;
        }
        &:hover + svg.icon-camera {
          display: block;
        }
      }

      &-vertical {
        width: 100%!important;
      }


      .icon-edit, .icon-camera {
        pointer-events: none;
        display: none;
        position: absolute;
      }
    }
    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      min-width: 120px;
      max-width: 120px;
      // margin-left: 8px;;
      // width: 186px;
      border-radius: 8px;
      transition: all 0.12s ease-out;
      position: relative;
      background: #e5e5e5;

      & img {
        display: block;
        height: 120px;
        width: 100%;
        width: auto;
        max-height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 8px;
        transition: all 0.12s ease-out;
        cursor: pointer;
        &:hover {
          filter: brightness(0.6);
          -webkit-filter: brightness(0.6);
        }
        &:hover + svg.icon-edit {
          display: block;
        }
        &:hover + svg.icon-camera {
          display: block;
        }
      }
      .icon-edit, .icon-camera {
        pointer-events: none;
        display: none;
        position: absolute;
      }
    }
  }

  &-scroll-footer {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;

    &-controls {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &-align-start {
        justify-content: flex-start;
        padding-left: 0!important;
      }
      &-align-end {
        justify-content: flex-end;
        padding-right: 0!important;
      }
    }

    &-quantity {
      display: flex;
      align-items: center;
      gap: 8px;

      &-icon {
        color: var(--gray-400);
      }
    }

    & span {
      font-family:var(--subheader-desktop-weight-2-font-family);
      color: var(--gray-400);
      font-weight: var(--subheader-desktop-weight-2-font-weight);
      line-height:  var(--subheader-desktop-weight-2-line-height);
      font-size:var(--subheader-desktop-weight-2-font-size);
      letter-spacing: var(--subheader-desktop-weight-2-letter-spacing);
    }
  }


}
