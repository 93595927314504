@import 'oelp-tokens/css/variables';
@import "../../mixin.scss";


.profile-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: var(--gray-800);

    &-button-change {
        width: 295px;
        height: 44px;
        border: none;
        outline: none;
        color: var(--surface-front);
        background-color: var(--interactive-default);
        border-radius: 8px;
    }

    &-section {
        display: flex;
        align-items:flex-start;
        gap: 20px;

        &-error {
            max-width: 288px;
            padding: 10px;
            color: #FF0000;
        }
}

    &-button {
        width: 295px;
        height: 44px;
        border: none;
        outline: none;
    }

    &-button-save {
        border-radius: 8px;
        color: var(--gray-400);
        width: 295px;
        height: 44px;
        border: none;
        outline: none;
    }

    &-button-save.no-errors {
        color: var(--surface-front);
        background-color: var(--interactive-default);
    }

    .change-password-button {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-self: flex-start;
        margin-top: 21px;
        padding: 15px 16px;
        width: 288px;
        height: 48px;
        border-radius: 8px;
    }

    @include lower-tablet {
        &-section {
            flex-direction: column;
        }
    }

    @include lower-mobile {
        gap: 16px;

        &-section {
            gap: 0px;
        }

        &-section {
            gap: 10px;
    
            &-error {
                padding: 0;
            }
        }

        &-button-save {
            color: var(--surface-front);
            background-color: var(--interactive-default);
            font-weight: normal;
            font-size: var(--font-size-x4);
            font-style: normal;
            line-height: 18px;
            letter-spacing: var(--letter-spacing-m0-28);
        }
    }
}
