@import 'oelp-tokens/css/variables';

table,
.divTable {
  display: flex;
  flex-direction: column;
  width: 100%!important;
  border: 1px solid var(--gray-100);
}

.thead{
  display: flex;
  flex-direction: column;
}

.tr {
  display: flex;
}

textarea, input{
  all: unset;
}

.cell-value {
  width: 100%;
}
tr,
.tr {
  display: flex;
  justify-content: space-between;
  width: 100%!important;
}

th,
.th,
td,
.td {
  display: flex;
  min-width: 100%!important;
  height: 100%;
  border: 1px solid var(--gray-100);
  padding: 0.25rem;
}

th,
.th {
  display: flex;
  min-width: 100px !important;
  padding: 2px 4px;
  position: relative;
  text-align: center;
  flex-wrap: nowrap;
  border: 1px solid var(--gray-100);
  word-break: break-word;
  color: var(--gray-900);
  font-family: var(--title-desktop-h4-weight-2-font-family);
  font-weight: var(--title-desktop-h4-weight-2-font-weight);
  line-height: var(--title-desktop-h4-weight-2-line-height);
  font-size: var(--title-desktop-h4-weight-2-font-size);
  letter-spacing: var(--title-desktop-h4-weight-2-letter-spacing);
  text-decoration: var(--title-desktop-h4-weight-2-text-decoration);
}
.editor {
  display: flex;
  height: 100%;
}

td,
.td {
  display: flex;
  min-height: 200px!important;
  min-width: 100px !important;
  overflow: hidden;
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: blue;
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}
