@import 'oelp-tokens/css/variables';

.form {
    & input {
        border: 1px solid var(--gray-100);
    }

    & button {
        border: 1px solid var(--gray-100);
    }
}
