@import 'oelp-tokens/css/variables';

.status-switcher {
  display: flex;
  background: var(--surface-front);
  border-radius: 8px;
}

.status-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
