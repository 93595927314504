@import 'oelp-tokens/css/variables';
@import "../../../mixin.scss";

.container {
  display: flex;
  height: 100%;
  background: var(--surface-mid);
  flex-direction: column;
  font-family: var(--font-families-main);
  width: 100%;
  max-width: 490px;
  overflow: scroll;
  scrollbar-width: none;

  gap: 16px;

  @include lower-tablet-landscape {
    max-width: 40%;
  }

}

.container::-webkit-scrollbar {
  display: none;
}
