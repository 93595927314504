@import 'oelp-tokens/css/variables';
@import "../../../mixin.scss";

.wrapper {
  display: grid;
  width: 100%;
  grid-template-areas: 'user search buttons';
  grid-template-columns: repeat(1fr);
  gap: 20px;

  &-user {
    grid-area: user;
    min-width: 48px;
  }

  &-search {
    grid-area: search;
    justify-self: center;
    width: 100%;
  }

  &-buttons {
    grid-area: buttons;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    flex: 1;
    .view-switcher-wrapper, .view-switcher-btn-wrapper {
      max-width: fit-content;
      &.view-switcher-list-view-bs {
        box-shadow: 0 0 0 1px var(--gray-100);
        border-radius: 8px;
        & > button {
          box-shadow: none;
        }
      }
    }
    &.contentStart{
      justify-content: start;
    }

    &.contentEnd {
      justify-content: end;
    }
  }

  @include lower-tablet-landscape {
    grid-template-columns: 40px auto 0.25fr;
  }

  @include lower-tablet {
    grid-template-areas: 'user search';
    grid-template-columns: 48px 1fr;

    &-buttons {
      display: none;
    }
  }

  @include lower-mobile-landscape {
    &-user {
      grid-area: user;
      max-width: 36px;
    }
  }

  &::before {
    display: none;
  }
}
