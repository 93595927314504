@import 'oelp-tokens/css/variables';
@import "../../mixin.scss";

.object-info {
  display: flex;
  flex-direction: column;

  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
    
    &-title {
      margin: 0;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;

      &-value {
        & > * {
          display: inline-block;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
