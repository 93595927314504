@import 'oelp-tokens/css/variables';

.portal-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background-color: var(--surface-back);
    z-index: 400;

    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }
}
