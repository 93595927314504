@import 'oelp-tokens/css/variables';

:root{
  --min-width-cell: 130px;
}

.divTable-analytics-table {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 0 0 2px var(--gray-100);
  background: var(--surface-back);
  overflow-x: auto;
  width: 100%;
}
.table-container-analytics-table{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.table-wrap-analytics-table{
  display: flex;
}
.thead-analytics-table{
  display: flex;
  flex-direction: column;
  width: inherit;
}
.tbody-analytics-table{
  display: flex;
  flex-direction: column;
  width: 100%;
}

textarea, input{
  all: unset;
}

.cell-value-analytics-table {
  width: 100%;
}

.tr-analytics-table {
  display: flex;
  //justify-content: space-between;
  width: 100%!important;
  //border-right: 2px solid #c8c8c8;
  &:last-child {
    border-bottom: 0;
  }
}
.tr-analytics-table-header{
  &:first-child{
    border-bottom: 0;
  }
}





.th-analytics-table {
  display: flex;
  width: inherit !important;
  min-width: var(--min-width-cell);
  flex: 1;
  padding: 2px 4px;
  position: relative;
  text-align: center;
  flex-wrap: nowrap;
  border-right: 2px solid var(--gray-100);
  border-bottom: 2px solid var(--gray-100);
  word-break: break-word;
  color: var(--gray-900);
  justify-content: center;
  align-items: center;
  height: inherit;
  min-height: 40px;
  font-family: var(--title-desktop-h4-weight-2-font-family);
  font-weight: var(--title-desktop-h4-weight-2-font-weight);
  line-height: var(--title-desktop-h4-weight-2-line-height);
  font-size: var(--title-desktop-h4-weight-2-font-size);
  letter-spacing: var(--title-desktop-h4-weight-2-letter-spacing);
  text-decoration: var(--title-desktop-h4-weight-2-text-decoration);
  &:first-child{
    min-width: 400px!important;
    @media all and (max-width: 1700px) {
      min-width: 175px!important;
    }
  }
  &:last-child{
    border-right: 0;
  }
}
.editor {
  display: flex;
  height: 100%;
}
.td-container-analytics-table{
  display: flex;
  width: 100%;
  flex-direction: column;
}

.td-analytics-table {
  display: flex;
  align-items: center;
  //min-width: inherit !important;
  width: 100%;
  min-width: var(--min-width-cell);
  overflow: hidden;
  min-height: 50px!important;
  height: inherit!important;
  max-height: 100%!important;
  font-family: var(--paragraph-desktop-x3-5-weight-1-font-family);
  font-weight :var(--paragraph-desktop-x3-5-weight-1-font-weight);
  line-height: var(--paragraph-desktop-x3-5-weight-1-line-height);
  font-size : var(--paragraph-desktop-x3-5-weight-1-font-size);
  letter-spacing: var(--paragraph-desktop-x3-5-weight-1-letter-spacing);
  text-decoration: var(--paragraph-desktop-x3-5-weight-1-text-decoration);
  justify-content: center;
  border-right: 2px solid var(--gray-100);
  border-bottom: 2px solid #c8c8c8;
  padding: 0.25rem;
  &:first-child{
    min-width: 400px!important;
    @media all and (max-width: 1700px) {
      min-width: 175px!important;
    }
  }
  &:last-child{
    border-right: 0;
  }

  &-row-type {
    justify-content: flex-start;
  }

  &-text-data{
    justify-content: center;
  }

  &-number-data{
    justify-content: center;
    // justify-content: flex-end;
  }
}
.analytics-table-footer{
  display: flex;
  width: 100%;
  padding: 20px 0;
  align-items: center;
  gap: 10px;
  @media all and (max-width: 1550px) {
    flex-direction: column;
  }
}
.resizer-analytics-table {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer-analytics-table .isResizing {
  background: blue;
  opacity: 1;
}

.not-editable-content{
  display: flex;
  min-width: fit-content;
  //height: 100%;
}

@media (hover: hover) {
  .resizer-analytics-table {
    opacity: 0;
  }

  *:hover > .resizer-analytics-table {
    opacity: 1;
  }
}
