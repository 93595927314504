@import "../../../mixin.scss";

.gallery {
  width: 100%;
  height: 100%;
  padding-top: 50px;

  &-slides {
    margin: 40px 0;
  }

  &-thumbnails {
    min-height: 155px;
    max-height: 155px;
    margin: 40px 0;
    padding: 0 40px;
  }

  &_hide-cursor {
    cursor: none;
  }

  @include lower-tablet-landscape {
    padding-top: 0;
    &-slides {
      margin: 85px 0 24px;
    }
    &-thumbnails {
      min-height: 12vh;
      max-height: 12vh;
      margin: 6px 0 24px;
    }
  }
}
