@import '/node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css';
@import '/node_modules/leaflet.markercluster/dist/MarkerCluster.css';
@import '/node_modules/react-leaflet-markercluster/dist/styles.min.css';
@import 'oelp-tokens/css/variables';

.marker-cluster-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--surface-front);
    text-align: center;
    border-style: solid;
    border-width: 4px;
    border-radius: 50%;

    div {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 32px;
        height: 32px;
        border-radius: 15px;
        font-size: var(--subheader-desktop-weight-2-font-size);
    }

    &.heat-veryBad {
        border-color: var(--heat-status-red-alpha);
        div {
            background-color: var(--heat-status-red);
        }
    }
    &.heat-badly {
        border-color: var(--heat-status-red-alpha);
        div {
            background-color: var(--heat-status-red);
        }
    }
    &.heat-satisfactory {
        border-color: var(--heat-status-yellow-alpha);
        div {
            background-color: var(--heat-status-yellow);
        }
    }
    &.heat-good {
        border-color: var(--heat-status-green-alpha);
        div {
            background-color: var(--heat-status-green);
        }
    }
    &.heat-perfectly {
        border-color: var(--heat-status-green-alpha);
        div {
            background-color: var(--heat-status-green);
        }
    }
    &.heat-gray {
        border-color: lightgray;
        div {
            background-color: gray;
        }
    }

    &.macro-gray{
        border-color: var(--heat-status-blue-alpha);
        div {
            background-color: var(--heat-status-blue);
        }
    }

    &.macro-blue{
        border-color: var(--macro-region-blue-alpha);
        div {
            background-color: var(--macro-region-blue);
        }
    }
    &.macro-pink{
        border-color: var(--macro-region-pink-alpha);
        div {
            background-color: var(--macro-region-pink);
        }
    }

    &.macro-orange{
        border-color: var(--macro-region-orange-alpha);
        div {
            background-color:var(--macro-region-orange);
        }
    }

    &.macro-violet{
        border-color: var(--macro-region-violet-alpha);
        div {
            background-color:var(--macro-region-violet);
        }
    }

    &.macro-darkGreen {
        border-color: var(--macro-region-dark-green-alpha);
        div {
            background-color:var(--macro-region-dark-green);
        }
    }

}

.marker-cluster-custom.subsidiaries {
    background-color: #0d47a1;
}

.marker-cluster-custom.departments {
    background-color: #1976d2;
}

.marker-cluster-custom.worships {
    background-color: #2196f3;
}

.marker-cluster-custom.schools {
    background-color: #64b5f6;
}

.marker-cluster-custom.sports {
    background-color: #4fc3f7;
}

.marker-cluster-custom.medicines {
    background-color: #03a9f4;
}

.marker-cluster-custom.leisure {
    background-color: #0288d1;
}



