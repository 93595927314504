@import "../../../../mixin.scss";

.thumbnail {
  overflow: hidden;

  width: 100%;
  height: 100%;

  opacity: 0.32;
  border-radius: 12px;

  transition: all .3s ease-out;

  &-image {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  &_active {
    opacity: 1;
  }

  &_inactive {
    @media all and (min-width: 768px) {
      &:hover {
        cursor: pointer;

        opacity: 0.8;
      }
    }
  }

  @include lower-tablet-landscape {
    border-radius: 6px;
    
    &_inactive {
      opacity: 32%;
    }
  }
}
