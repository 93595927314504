@import 'oelp-tokens/css/variables';


.container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    background-color: var(--gray-50);
    z-index: 20;

    overflow: auto;

    .table {
      padding: 120px 40px 20px 40px;
      width: 100%;
    }

    .items-list {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        gap: 28px;

        width: 100%;
    }
}

.content-wrapper-container {
  background-color: var(--gray-50);
  z-index: 2;
}


.header-wrapper{
    display: flex;
    flex: 1;
    z-index: 120;
    align-items: flex-start;
    justify-content: center;
    &-start{
      justify-content: flex-start;
    }
  }

  .info-wrapper {
    max-width: 400px;
    min-width: 350px;
    padding: 12px 16px;
    gap: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: var(--surface-front);

    > h4 {
      margin: 0;

      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: ellipsis;
    }
  }
  .right-btns-wrapper {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 20px;
    flex: 1;
    .view-switcher-wrapper{
      max-width: fit-content;
    }
    &.contentStart{
      justify-content: start;
    }
  
    &.contentEnd {
      justify-content: end;
    }
  }
