@import "../../../mixin.scss";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;
  padding: 12px 0;

  @include lower-tablet {
    height: 100%;
    padding: 12px 16px 8px;
  }
}
