@import 'oelp-tokens/css/variables';
@import "../../../mixin.scss";


.container {
  display: flex;
  gap: 16px;

  &-button {
    &-close {
      width: 100px;
    }

    &-logout {
      width: 60px;
    }
  }

  @include lower-mobile-landscape {

    &-button {
      width: 100%;
    }

    &-mobile-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 2rem;
      gap: 26px;
    }
  }
  
}

