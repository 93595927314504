@import 'oelp-tokens/css/variables';
@import '../../../mixin.scss';

.container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 350px);
    gap: 16px;
    padding: 120px 40px;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: auto;

    @include lower-tablet-landscape {
        display: flex;
        flex-wrap: wrap;
        grid-area: 16px;
        padding: 80px 20px 20px 20px;
    }

    @include lower-tablet {
        padding: 0 16px 16px 16px;
    }
}

.container::-webkit-scrollbar {
    display: none;
}
.scroll-to-top-btn{
    position: fixed;
    max-width: 40px !important;
    max-height: 43px !important;
    padding: 8px !important;
    bottom: 40px;
    left: 20px;
    background: var(--surface-front)!important;
    box-shadow: 
        var(--shadow-popup-tablet-x) 
        var(--shadow-popup-tablet-y) 
        var(--shadow-popup-mobile-blur) 
        var(--shadow-popup-tablet-spread) 
        var(--shadow-popup-tablet-color)
        !important;
}


