@import 'oelp-tokens/css/variables';
@import "../../../mixin.scss";

.gallery-panel {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: end;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3000;
    width: 100%;
    height: 100%;
    background: var(--surface-back);

    &-thumbnails-container {
      min-height: 100px;
      max-height: 100px;
    }

  img {
    opacity: 1;
  }


  &_btn-box {
      position: absolute;
      display: flex;
      top: 10px;
      right: 10px;
      z-index: 1;

  &_add-btn {
      text-align: center;
      cursor: pointer;
      user-select: none;
      color: var(--surface-front);
      background-color: var(--interactive-default);
      font-size: 16px;
      letter-spacing: var(--letter-spacing-m0-28);
      line-height: 18px;
      padding: 8px;
      height: 34px;
      border: none;
      border-radius: 8px;
      width: 144px;
      margin-right: 16px;
    img {
        margin-right: 6px;
    }
    input[type="file"] {
        display: none;
    }
  }
  &_delete-btn {
      color: var(--surface-front);
      background-color: var(--validation-error-main);
      font-size: 16px;
      letter-spacing: var(--letter-spacing-m0-28);
      line-height: 18px;
      padding: 8px;
      user-select: none;
      height: 34px;
      border: none;
      border-radius: 8px;
      width: 133px;
    img {
        margin-right: 6px;
    }
  }
    .button-close {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        right: -20px;
    }
  }

  @include lower-tablet-landscape {
    &-thumbnails-container {
      min-height: 76px;
      max-height: 76px;
    }

    &-navigation-button-prev {
        padding-left: 16px!important;
    }
    
    &-navigation-button-next {
        padding-right: 16px;
    }
  }

}

.gallery-panel-popup-message {
     width: 290px;
     border: none;
     border-radius: 10px;
     font-size: 16px;
     letter-spacing: var(--letter-spacing-m0-28);
     line-height: 18px;
     font-weight: 600;

&-title {
     font-size: 16px;
     font-weight: 600;
 }

&-body {
     font-size: 14px;
     letter-spacing: var(--letter-spacing-m0-28);
     line-height: 18px;
     font-weight: 500;
 }
}
