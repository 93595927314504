@import 'oelp-tokens/css/variables';
@import "../../../mixin.scss";

.search-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 12px;
  transition: all 0.12s ease-out;
  gap: 10px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background: var(--interactive-light-hover);
  }

  &-active {
    background: var(--interactive-light-hover);
  }

  &-text {
    color: var(--gray-900);
    font-family: var(--button-desktop-lite-font-family);
    font-weight: var(--button-desktop-lite-font-weight);
    line-height: var(--button-desktop-lite-line-height);
    font-size: var(--button-desktop-lite-font-size);
    letter-spacing: var(--button-desktop-lite-letter-spacing);
    text-decoration: var(--button-desktop-text-decoration);
    // margin-top: 10px;
    &-bold {
      color: var(--gray-900);
      font-family: var(--button-desktop-lite-font-family);
      line-height: var(--button-desktop-lite-line-height);
      font-size: var(--button-desktop-lite-font-size);
      letter-spacing: var(--button-desktop-lite-letter-spacing);
      text-decoration: var(--button-desktop-text-decoration);
      font-weight: var(--title-desktop-h4-weight-3-font-weight);
    }
  }

  &-search-tooltip {
    color: var(--gray-900);
    font-family: var(--caption-desktop-x2-75-weight-1-font-family);
    font-weight: var(--caption-desktop-x2-75-weight-1-font-weight);
    line-height: var(--caption-desktop-x2-75-weight-1-line-height);
    font-size: var(--caption-desktop-x2-75-weight-1-font-size);
    letter-spacing: var(--caption-desktop-x2-75-weight-1-letter-spacing);
    text-decoration: var(--caption-desktop-x2-75-weight-1-paragraph-spacing);

    &-bold{
      color: var(--gray-900);
      font-family: var(--title-desktop-h4-weight-3-font-family);
      font-weight: var(--title-desktop-h4-weight-3-font-weight);
      line-height: var(--title-desktop-h4-weight-3-line-height);
      font-size: var(--caption-desktop-x2-75-weight-1-font-size);
      letter-spacing: var(--title-desktop-h4-weight-3-letter-spacing);
      text-decoration: var(--title-desktop-h4-weight-3-text-decoration);
    }
  }


  @include lower-mobile {
    padding: 8px 12px;

    &-text {
      font-family: var(--button-mobile-lite-font-family);
      font-weight: var(--button-mobile-lite-font-weight);
      line-height: var(--button-mobile-lite-line-height);
      font-size: var(--button-mobile-lite-font-size);
      letter-spacing: var(--button-mobile-lite-letter-spacing);
      text-decoration: var(--button-mobile-text-decoration);

      &-bold {
        font-family: var(--button-mobile-lite-font-family);
        line-height: var(--button-mobile-lite-line-height);
        font-size: var(--button-mobile-lite-font-size);
        letter-spacing: var(--button-mobile-lite-letter-spacing);
        text-decoration: var(--button-mobile-text-decoration);
        font-weight: var(--title-mobile-h4-weight-3-font-weight);
      }
    } 

    &-search-tooltip {
      font-family: var(--caption-mobile-x2-75-weight-1-font-family);
      font-weight: var(--caption-mobile-x2-75-weight-1-font-weight);
      line-height: var(--caption-mobile-x2-75-weight-1-line-height);
      font-size: var(--caption-mobile-x2-75-weight-1-font-size);
      letter-spacing: var(--caption-mobile-x2-75-weight-1-letter-spacing);
      text-decoration: var(--caption-mobile-x2-75-weight-1-paragraph-spacing);
  
      &-bold{
        font-family: var(--title-mobile-h4-weight-3-font-family);
        font-weight: var(--title-mobile-h4-weight-3-font-weight);
        line-height: var(--title-mobile-h4-weight-3-line-height);
        font-size: var(--caption-mobile-x2-75-weight-1-font-size);
        letter-spacing: var(--title-mobile-h4-weight-3-letter-spacing);
        text-decoration: var(--title-mobile-h4-weight-3-text-decoration);
      }
    }
  } 
}
